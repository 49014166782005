import FormSkeletonLoader from "@flanksource-ui/ui/SkeletonLoader/FormSkeletonLoader";
import {
  LoginFlow as SelfServiceLoginFlow,
  RecoveryFlow as SelfServiceRecoveryFlow,
  RegistrationFlow as SelfServiceRegistrationFlow,
  SettingsFlow as SelfServiceSettingsFlow,
  VerificationFlow as SelfServiceVerificationFlow,
  UpdateLoginFlowBody as SubmitSelfServiceLoginFlowBody,
  UpdateRecoveryFlowBody as SubmitSelfServiceRecoveryFlowBody,
  UpdateRegistrationFlowBody as SubmitSelfServiceRegistrationFlowBody,
  UpdateSettingsFlowBody as SubmitSelfServiceSettingsFlowBody,
  UpdateVerificationFlowBody as SubmitSelfServiceVerificationFlowBody,
  UiNode
} from "@ory/client";
import {
  filterNodesByGroups,
  getNodeId,
  isUiNodeInputAttributes
} from "@ory/integrations/ui";
import { Component, FormEvent } from "react";
import { Messages } from "./Messages";
import { Node } from "./Node";

export type Values = Partial<
  | SubmitSelfServiceLoginFlowBody
  | SubmitSelfServiceRegistrationFlowBody
  | SubmitSelfServiceRecoveryFlowBody
  | SubmitSelfServiceSettingsFlowBody
  | SubmitSelfServiceVerificationFlowBody
>;

export type Methods =
  | "oidc"
  | "password"
  | "profile"
  | "totp"
  | "webauthn"
  | "link"
  | "lookup_secret";

export type Props<T> = {
  // The flow
  flow?:
    | SelfServiceLoginFlow
    | SelfServiceRegistrationFlow
    | SelfServiceSettingsFlow
    | SelfServiceVerificationFlow
    | SelfServiceRecoveryFlow;
  // Only show certain nodes. We will always render the default nodes for CSRF tokens.
  only?: Methods;
  // Is triggered on submission
  onSubmit: (values: T) => Promise<void>;
  // Do not show the global messages. Useful when rendering them elsewhere.
  hideGlobalMessages?: boolean;
  isLoginFlow?: boolean;
};

function emptyState<T>() {
  return {} as T;
}

type State<T> = {
  values: T;
  isLoading: boolean;
};

export class Flow<T extends Values> extends Component<Props<T>, State<T>> {
  constructor(props: Props<T>) {
    super(props);
    this.state = {
      values: emptyState(),
      isLoading: false
    };
  }

  componentDidMount() {
    this.initializeValues(this.filterNodes());
  }

  componentDidUpdate(prevProps: Props<T>) {
    if (prevProps.flow !== this.props.flow) {
      // Flow has changed, reload the values!
      this.initializeValues(this.filterNodes());
    }
  }

  initializeValues = (nodes: Array<UiNode> = []) => {
    // Compute the values
    const values = emptyState<T>();
    nodes.forEach((node) => {
      // This only makes sense for text nodes
      if (isUiNodeInputAttributes(node.attributes)) {
        if (
          node.attributes.type === "button" ||
          node.attributes.type === "submit"
        ) {
          // In order to mimic real HTML forms, we need to skip setting the value
          // for buttons as the button value will (in normal HTML forms) only trigger
          // if the user clicks it.
          return;
        }
        values[node.attributes.name as keyof Values] = node.attributes.value;
      }
    });

    // Set all the values!
    this.setState((state) => ({ ...state, values }));
  };

  filterNodes = (): Array<UiNode> => {
    const { flow, only } = this.props;
    if (!flow) {
      return [];
    }
    return flow.ui.nodes.filter(({ group }) => {
      if (!only) {
        return true;
      }
      return group === "default" || group === only;
    });
  };

  // Handles form submission
  handleSubmit = (e: MouseEvent | FormEvent) => {
    // Prevent all native handlers
    e.stopPropagation();
    e.preventDefault();

    // Prevent double submission!
    if (this.state.isLoading) {
      return Promise.resolve();
    }

    this.setState((state) => ({
      ...state,
      isLoading: true
    }));

    return this.props.onSubmit(this.state.values).finally(() => {
      // We wait for reconciliation and update the state after 50ms
      // Done submitting - update loading status
      this.setState((state) => ({
        ...state,
        isLoading: false
      }));
    });
  };

  render() {
    const { hideGlobalMessages, flow } = this.props;
    const { values, isLoading } = this.state;

    // Filter the nodes - only show the ones we want
    const nodes = this.filterNodes();

    if (!flow) {
      // No flow was set yet? It's probably still loading...
      //
      // Nodes have only one element? It is probably just the CSRF Token
      // and the filter did not match any elements!
      return <FormSkeletonLoader />;
    }

    return (
      <div className="flex flex-col gap-2">
        {!hideGlobalMessages ? <Messages messages={flow.ui.messages} /> : null}

        {this.props.isLoginFlow ? (
          <>
            <form
              className="space-y-8"
              action={flow.ui.action}
              method={flow.ui.method}
              onSubmit={this.handleSubmit}
            >
              {filterNodesByGroups({
                nodes: nodes,
                groups: ["default", "password"]
              }).map((node, k) => {
                const id = getNodeId(node) as keyof Values;
                return (
                  <Node
                    key={`${id}-${k}`}
                    disabled={isLoading}
                    node={node}
                    value={values[id]}
                    dispatchSubmit={this.handleSubmit}
                    setValue={(value) =>
                      new Promise((resolve) => {
                        this.setState(
                          (state) => ({
                            ...state,
                            values: {
                              ...state.values,
                              [getNodeId(node)]: value
                            }
                          }),
                          resolve
                        );
                      })
                    }
                  />
                );
              })}
            </form>

            {/* Hide hr if we don't have an OIDC group */}
            {filterNodesByGroups({
              nodes: nodes,
              groups: ["oidc"],
              withoutDefaultGroup: true
            }).length > 0 && <hr className="my-3 border-gray-200" />}

            <form action={flow.ui.action} method={flow.ui.method}>
              <div className="flex flex-col">
                {filterNodesByGroups({
                  nodes: nodes,
                  groups: ["oidc"],
                  withoutDefaultGroup: true
                }).map((node, k) => {
                  const id = getNodeId(node) as keyof Values;
                  return (
                    <Node
                      key={`${id}-${k}`}
                      disabled={isLoading}
                      node={node}
                      value={values[id]}
                      dispatchSubmit={this.handleSubmit}
                      setValue={(value) =>
                        new Promise((resolve) => {
                          this.setState(
                            (state) => ({
                              ...state,
                              values: {
                                ...state.values,
                                [getNodeId(node)]: value
                              }
                            }),
                            resolve
                          );
                        })
                      }
                    />
                  );
                })}
              </div>
            </form>
          </>
        ) : (
          <form
            action={flow.ui.action}
            method={flow.ui.method}
            className="flex flex-col gap-4"
          >
            {nodes.map((node, k) => {
              const id = getNodeId(node) as keyof Values;
              return (
                <Node
                  key={`${id}-${k}`}
                  disabled={isLoading}
                  node={node}
                  value={values[id]}
                  dispatchSubmit={this.handleSubmit}
                  setValue={(value) =>
                    new Promise((resolve) => {
                      this.setState(
                        (state) => ({
                          ...state,
                          values: {
                            ...state.values,
                            [getNodeId(node)]: value
                          }
                        }),
                        resolve
                      );
                    })
                  }
                />
              );
            })}
          </form>
        )}
      </div>
    );
  }
}
