export function TopologyIcon({ className }: { className: string }) {
  return (
    <svg
      className={className}
      x="0px"
      y="0px"
      viewBox="0 0 68 68"
      fill="currentColor"
    >
      <g>
        <path d="M39.9549561,23.8499756c-0.539978-0.4000244-1.1199951-0.75-1.7299805-1.0300293   c-1.2800293-0.5999756-2.710022-0.9400024-4.2200317-0.9400024c-2.5999756,0-4.9599609,0.9900513-6.7299805,2.6300049   c-0.5100098,0.460022-0.9699707,0.9800415-1.3599854,1.5300293c-1.1699829,1.6300049-1.8599854,3.6300049-1.8599854,5.7799683   c0,1.4800415,0.3299561,2.8900146,0.9099731,4.1500244c0.2800293,0.6099854,0.6300049,1.1900024,1.0200195,1.7299805   c1.6199951,2.2200317,4.1400146,3.7300415,7.0199585,4.0200195c0.3300171,0.0300293,0.6600342,0.0499878,1,0.0499878   c0.3400269,0,0.6700439-0.0199585,1-0.0499878c3.9500122-0.4000244,7.2200317-3.1099854,8.4300537-6.7600098   C43.6449585,34.3299561,43.7949829,33.6799927,43.875,33c0.0499878-0.3900146,0.0699463-0.7800293,0.0699463-1.1800537   C43.9449463,28.5699463,42.375,25.6699829,39.9549561,23.8499756z"></path>
        <path d="M35.0049438,55.1499634V43.7199707c-0.3399658,0.039978-0.6699829,0.0499878-1,0.0499878   c-0.3399658,0-0.6699829-0.0100098-1-0.0499878v11.4299927c-2.5499878,0.4700317-4.4799805,2.7000122-4.4799805,5.3800049   C28.5249634,63.539978,30.9849854,66,34.0049438,66c3.0100098,0,5.4700317-2.460022,5.4700317-5.4700317   C39.4749756,57.8499756,37.5449829,55.6199951,35.0049438,55.1499634z"></path>
        <path d="M58.4049683,30.0799561c-2.6199951,0-4.8300171,1.8500366-5.3599854,4.3200073l-7.2000122-1.0999756   c-0.0700073,0.6499634-0.210022,1.3200073-0.4099731,1.9699707l7.5699463,1.1500244   c0.4100342,2.6099854,2.6800537,4.5999756,5.4000244,4.5999756c3.0200195,0,5.4700317-2.4500122,5.4700317-5.4699707   C63.875,32.5299683,61.4249878,30.0799561,58.4049683,30.0799561z"></path>
        <path d="M16.3249512,16.8199463c0.9000244,0,1.7600098-0.2199707,2.5100098-0.6099854l5.8599854,8.1300049   c0.3699951-0.4699707,0.7900391-0.9099731,1.2400513-1.3099976c0.0599976-0.0499878,0.1099854-0.0999756,0.1699829-0.1500244   l-5.7000122-7.8999634c0.8699951-0.960022,1.3900146-2.2399902,1.3900146-3.6400146   c0-3.0100098-2.4500122-5.4699707-5.4700317-5.4699707c-3.0199585,0-5.4699707,2.4599609-5.4699707,5.4699707   C10.8549805,14.3599854,13.3049927,16.8199463,16.3249512,16.8199463z"></path>
        <path d="M41.0849609,22.1999512l6.5499878-9.5999756c0.5800171,0.2199707,1.210022,0.3399658,1.8600464,0.3399658   c3.0199585,0,5.4799805-2.4499512,5.4799805-5.4699707S52.5149536,2,49.4949951,2   c-3.0100098,0-5.4700317,2.4499512-5.4700317,5.4699707c0,1.6400146,0.7299805,3.1099854,1.8800049,4.1099854l-6.539978,9.5700073   C39.9449463,21.4399414,40.5349731,21.789978,41.0849609,22.1999512z"></path>
        <path d="M23.1449585,36.8099976l-9.1499634,4.2199707c-0.9800415-1.3599854-2.5900269-2.2399902-4.4000244-2.2399902   C6.5849609,38.789978,4.125,41.25,4.125,44.2599487c0,3.0200195,2.4599609,5.4700317,5.4699707,5.4700317   c3.0200195,0,5.4799805-2.4500122,5.4799805-5.4700317c0-0.5-0.0700073-0.9699707-0.1999512-1.4299927l9.2599487-4.2799683   C23.7449951,37.9699707,23.414978,37.3899536,23.1449585,36.8099976z"></path>
      </g>
    </svg>
  );
}
